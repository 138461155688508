import React from "react";

const Input = ({ placeholder, type, onChange, className="blank", name, autocomplete }) => {
    return (
        <div className={className}>
            <input 
                placeholder={placeholder}
                type={type}
                onChange={e => onChange(e)} 
                name={name}
                autoComplete={autocomplete}
            />
        </div>
    );
}

export default Input;

