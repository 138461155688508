import React from "react";

const Footer = () => {
    return (
        <nav className="nav__menu">
            <div className="footer__row">
                <hr/>
                <p className="nav__link">Þjóðríkur – málgagn | 800 Selfoss | frodi_sudurland@outlook.com</p>
            </div>
        </nav>
    )
}

export default Footer;

