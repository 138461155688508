import fetch from "isomorphic-fetch";
import resource from "../resources/datastore.json";

export const getByCriteria = (predicate, key) => {
    const source = resource[key];
    return predicate ? source.filter(predicate) : source;
};


export const fetchTokenFromLocalstorage = () => {
    let token = localStorage.getItem("code") ? localStorage.getItem("code") : null;

    return token;
};

export const loginOrRegister = (endpoint, stringdata) => {
    let length = stringdata.length;

    return fetch(endpoint, { method: "POST", headers: { "Content-Type": "application/json", "Content-Length": length }, body: stringdata })
        .then((resp) => {
            return resp.json();
        });
};

export const postdata = (endpoint, stringdata) => {
    let length = stringdata.length;

    return fetch(endpoint, { method: "POST", headers: { "Content-Type": "application/json", "Content-Length": length }, body: stringdata })
        .then((resp) => {
            if (resp.status === 400) return 400;
            if (resp.status === 200) return 200;
            if (resp.status === 204) return 204;
        
            return resp.json();
        })
};

export const logout = (querypath) => {
    localStorage.clear();

    return fetch(querypath, { method: "POST", headers: { "Content-Type": "application/json" } }).then((resp) => {
        return resp;
    });
};

export const compareCount = (a, b) => { 
    if(a.clicks > b.clicks) { return -1 }
    else if(a.clicks < b.clicks) { return 1 }
    
    return 0;            
}

export const compareDate = (a, b) => { 
    if(a.publishdate > b.publishdate) { return -1 }
    else if(a.publishdate < b.publishdate) { return 1 }
    
    return 0;            
}

export const write = (a, b) => { 
    if(a.publishdate > b.publishdate) { return -1 }
    else if(a.publishdate < b.publishdate) { return 1 }
    
    return 0;            
}


