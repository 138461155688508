import React from "react";

const Checkbox = ({ title, type, id, name, value, onChange, htmlfor, label }) => {
    return (
        <fieldset>
            <legend>{title}</legend>
            <input 
                type={type} 
                id={id} 
                name={name}
                value={value} 
                onChange={(e) => onChange(e)}
            />
            <label htmlFor={htmlfor}>{label}</label>
        </fieldset>
    );
}

export default Checkbox;
