import React from "react";

import { StatusMessage } from "../component/StatusMessage/StatusMessage";

import { getByCriteria } from "../services/common";

class ArticlePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articleID: "",
            hasID: false,
            html: null,
            status: "ok",
            articles: []
        }
    }

    componentDidMount() {
		const { hash } = window.location;

        const articles = getByCriteria((x => x), "articles");

		const articleID = hash.split("#/articles/")[1];
        const article = articles.find((ed) => (ed.id === articleID));

        if (article.content && article.content.length > 3) {
            document.getElementById("content").innerHTML = article.content;
        } else { 
            this.setState({ status: "no_article" }); 
        }
    }

    render () {
        const { status } = this.state;
    
        if(status === "no_article") { return ( StatusMessage("no_article") ); }

        return (<div id="content" className="page__row" />);
    }
};

export default ArticlePage;
