import React from "react";
import Searchbar from "../component/Searchbar/Searchbar";
import Footer from "../component/Footer/Footer";

import { withRouter } from "react-router-dom";

import { compareDate, getByCriteria } from "../services/common";

import ListArticle from "../component/ListArticle/ListArticle";
import 'regenerator-runtime/runtime';


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            moreonewest: 4,
            moresuggestions: 4,
            morepopular: 4,
            searcheditems: [],
            articles: [],
            searching: "no"
        }
    }

    componentDidMount() {

        const date = new Date(Date.now()).toISOString();

        const articles = getByCriteria((x => (x.publishdate < date) && x.publish), "articles");
        this.setState({ articles: articles });        
    }

    searchOnKeyPress(e) {
        const { articles } = this.state;
        if(e.code === "Enter" || e.code === "NumpadEnter") {
            let q = e.target.value ? e.target.value : "";
            let results = articles.filter(ed => ed.title.toLowerCase().includes(q.toLowerCase()));

            this.setState({ searcheditems: results, searching: "yes" });
        }
    }

    render() {
        const { moreonewest, searcheditems, searching } = this.state;

        console.log(new Date(Date.now()).toISOString());


        if (searching === "yes") {
            return (
                <div className="page">
                    <div className="page__row">
                        <div>
                            {searcheditems.map((si) => <ListArticle key={si.id} article={si} history={this.props.history} />)}
                        </div>
                        <div>
                            {searcheditems.length === 0 ? <section><p>Ekkert fannst</p></section> : null}
                            <p
                                style={{ margin: "5px 0", color: "blue", cursor: "pointer", textDecoration: "underline" }}
                                onClick={() => this.setState({ searching: "no" })}
                            >Heim...</p>
                        </div>
                    </div>
                </div>
            )
        }

        // Links to useful pages, such as communist material.
        return (
            <React.Fragment>
                <div className="page__row">
                    <section>
                        <Searchbar onKeyDown={(e) => this.searchOnKeyPress(e)} />                   
                    </section>
                    <section>
                        <h2 className="page__row__title">Nýjast</h2>
                        <ul className="articlesul">
                            {this.state.articles.sort((a, b) => compareDate(a, b)).slice(0, moreonewest).map(ex => {
                                return (
                                    <li onClick={() => this.props.history.push(`/articles/${ex.id}`)} key={ex.id} className="articles__container" style={{ background: `center / cover no-repeat url(${ex.image})`}} >
                                        <h3 style={{ width: "fit-content", padding: "15px", backgroundColor: "#302c28" }} className="page__row__title">{ex.title}</h3>
                                        <h4 style={{ width: "fit-content", padding: "15px", backgroundColor: "#302c28" }} className="page__row__title page__row__title_xs">{ex.publishdate.toLowerCase().split("t")[0]}</h4>                                        
                                    </li>
                                );
                            })}
                        </ul>
                        <br />
                        {/*eslint-disable-next-line*/}
                        <a 
                            href=""
                            style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                                onClick={(e) => {
                                    this.setState({ moreonewest: (moreonewest < 6) ? moreonewest + 2 : moreonewest });
                                    e.preventDefault();
                                }}
                            >Eldra...</a>
                    </section>
                {/*

                <section>
                    <h2 className="page__row__title">Álit</h2>
                    <ul className="articlesul">
                        {this.state.articles.filter(ed => (ed.articletype === "suggestions")).slice(0, moresuggestions).map(ex => {
                            return (
                                <li onClick={() => this.props.history.push(`/articles/${ex.id}`)} key={ex.id} className="articles__container" style={{ background: `center / cover no-repeat url(${ex.image})`}} >
                                    <h3 className="page__row__title">{ex.title}</h3>
                                </li>
                            );
                        })}
                    </ul>
                    <br />
                    <a
                        href=""
                        style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                            onClick={(e) => {
                                this.setState({ moresuggestions: (moresuggestions < 12) ? moresuggestions + 4 : moresuggestions });
                                e.preventDefault();
                            }}
                        >Eldra...</a>
                </section>
                
                <section>
                    <h2 className="page__row__title">Mest lesið</h2>
                    <ul className="articlesul">
                        {this.state.articles.sort((a, b) => compareCount(a, b)).slice(0, morepopular).map(ex => {
                            return (
                                <li onClick={() => this.props.history.push(`/articles/${ex.id}`)} key={ex.id} className="articles__container" style={{ background: `center / cover no-repeat url(${ex.image})`}}>
                                    <h3 className="page__row__title">{ex.title}</h3>
                                </li>
                            );
                        })}

                    </ul>
                    <br />
                    <a
                        href=""
                        style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                        onClick={(e) => {
                            this.setState({ morepopular: (morepopular < 12) ? morepopular + 4 : morepopular });
                            e.preventDefault();
                        }}
                    >Eldra...</a>
                </section>
          
                */}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(HomePage);

