import React from "react";

const RadioButton = ({ onChange, type, id, name, value, d, htmlfor, label }) => {
    return (
        <div>
            <input onChange={() => onChange()} type={type} id={id} name={name} value={value} defaultChecked={d} />
            <label htmlFor={htmlfor}>{label}</label>
        </div>
    );
}

export default RadioButton;
