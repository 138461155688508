import React from 'react';
import { Route, Switch } from "react-router-dom";

import Header from './component/Header/Header';

import NotFound from './pages/404';
import HomePage from './pages/HomePage';
import Admin from './pages/Admin';
import Post from "./pages/Post";
import ArticlePage from './pages/ArticlePage';
import AllArticles from "../src/pages/AllArticles";

import './styles/main.css';

function App() {

  return (
    <div>
      <Header />
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/articles/:id" component={ArticlePage} />
            <Route exact path="/articles" component={AllArticles} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/posts" component={Post} />
            <Route exact path="*" component={NotFound} />
        </Switch>
    </div>
  );
}

export default App;
