import React from "react";

import { fetchTokenFromLocalstorage, postdata } from "../services/common";

import SelectDate from "../component/SelectDate/SelectDate";
import RadioButton from "../component/RadioButton/RadioButton";
import Checkbox from "../component/Checkbox/Checkbox";
import Input from "../component/Input/Input";
import Textarea from "../component/Textarea/Textarea";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            content: "",
            title: "",
            author: "",
            articletype: "suggestions",
            image: "https://images.unsplash.com/photo-1523995462485-3d171b5c8fa9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1335&q=80",
            statuscode: 0,
            publish: false,
            publishdate: Date.now(),
            clicks: 1
        }
    }

    componentDidMount() {
        const code = fetchTokenFromLocalstorage();

        console.log(code);
        postdata("http://localhost:5000/verifylogin", JSON.stringify({ code }))
            .then(resp => {
                if (resp) { this.setState({ statuscode: resp }); }
            }).catch(err => {
                if (err) { console.log(err); }
            });
    }

    post() {
        const { content, publish, articletype, author, publishdate, title, image, clicks } = this.state;

        let article = {
            title,
            content: content,
            articletype,
            author,
            publish,
            publishdate,
            image,
            clicks
        };

        console.log(article);
        postdata("http://localhost:5000/api/v1/articles", JSON.stringify({ article }))
            .then(resp => {
                if (resp) {
                    console.log(resp);
                }
            }).catch(err => {
                if (err) { console.log(err); }
            });
    }
    

    render() {
        const { statuscode, publish, content } = this.state;

        if(statuscode === 0) { 
            return (
                <div className="page">
                    <section className="page__row">
                        <h2>Wait...</h2>
                    </section>
                </div>
            )
        };

        if(statuscode !== 200) { 
            return (
                <div className="page">
                    <section className="page__row">
                        <h2>No access.</h2>
                    </section>
                </div>
            )
        };

        console.log(content);
        return (
            <div className="page__row">
                <h2>Text block editor</h2>
                <div style={{ display: "inline-block", margin: "10px 0" }}>
                        <ReactQuill style={{height: "500px", width: "700px" }} theme="snow" value={this.state.content} onChange={(e) => {
                            this.setState({ content: e })
                        }} />
                </div>
                <h2>HTML readonly </h2>
                <div className="postcontainer" >
                    <td style={{ padding: "1em", height: "500px", minWidth: "700px", boxSizing: "border-box", border: "1px solid #ccc" }} dangerouslySetInnerHTML={{__html: content}} />
                </div>
                <Input placeholder="Title" type="text" onChange={e => this.setState({ title: e.target.value })} className="postcontainer" name="title" autocomplete="name" />

                <Input placeholder="Author" onChange={e => this.setState({ author: e.target.value })} className="postcontainer" />
                <Input placeholder="Image" onChange={e => this.setState({ image: e.target.value })} className="postcontainer" />
                <div className="postcontainer">
                    
                    <Checkbox title="Published" type="checkbox" id="publish" name="publish" value={publish ? false : true} onChange={(e) => this.setState({ publish: e.target.value === "true" ? true : false })} htmlfor="articletype" label="Publish" />
                    <br/>
                    <SelectDate 
                        title="Publishing Date" 
                        type="date" 
                        id="date" 
                        name="date"
                        onChange={(e) => this.setState({ publishdate: new Date(e.target.value).toISOString() })} 
                    />
                    <br/>
                    <fieldset>
                        <legend>Article Type</legend>
                        <RadioButton onChange={() =>  this.setState({ articletype: "suggestions" })} type="radio" id="suggestion" name="articletype" value="suggestion" d={true} htmlfor="suggestion" label="Suggestion" />
                        <RadioButton onChange={() =>  this.setState({ articletype: "suggestions" })} type="radio" id="opinion" name="articletype" value="opinion" d={true} htmlfor="opinion" label="Opinion" />
                        <RadioButton onChange={() =>  this.setState({ articletype: "suggestions" })} type="radio" id="news" name="articletype" value="news" d={true} htmlfor="news" label="News" />
                    </fieldset>
                </div>
                <div className="postcontainer" >
                    <button
                        className="input-btn"
                        onClick={() => this.post()}
                    >Send</button>
                </div>
            </div>
        );
    }
}

export default Post;

