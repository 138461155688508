import React from "react";

class NotFound extends React.Component {
    
    render () {
        return (
            <div className="page__row" >
                <h2>404</h2>                
            </div>
        );
    }
} 

export default NotFound;

