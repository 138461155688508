import React from "react";

export const StatusMessage = (status) => {
    if (status === "password_err") {
        return (
            <div style={{ margin: "0 auto"}} className="message-div message-div_color_r message-div_margin_t">
                <p>Wrong username or password.</p>
            </div>
        );
    } else if (status === "no_article") {
        return (
            <div style={{ margin: "30px auto" }} className="message-div message-div_color_b message-div_margin_t">
                <p>Article not found.</p>
            </div>
        );
    };
    return <noscript />;
};