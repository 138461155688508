import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <nav className="nav__top">
            <div className="nav__row">
                <h1 className="nav__heading">
                    <Link to="/">Fróði</Link>
                </h1>
                <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
                    <li><Link to="/articles"><h2 style={{ fontSize: "0.9rem", textDecoration: "underline" }} >Allt efni</h2></Link></li>
                </ul>
            </div>
        </nav>
    );
}

export default Header;

