import React from "react";

const SelectDate = ({ title, type, id, name, onChange }) => {
    return (
        <fieldset>
            <legend>{title}</legend>
            <input 
                type={type} 
                id={id} 
                name={name} 
                onChange={(e) => onChange(e)}
            />
        </fieldset>
    );
}

export default SelectDate;

