import React from "react";
import { withRouter } from "react-router-dom";

import { StatusMessage } from "../component/StatusMessage/StatusMessage";
import Input from "../component/Input/Input";

import { loginOrRegister } from "../services/common";

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            passwordRepeat: "",
            status: ""
        };
    }

    post() {
        const { username, password, passwordRepeat } = this.state;
        let data = { username, password, passwordRepeat };

//        let query = process.env.API_BACKEND + "/admin";
        loginOrRegister("http://localhost:5000/admin", JSON.stringify(data)).then((resp) => {
            console.log(resp);
            if (resp) { 
                localStorage.setItem("code", resp.code);
                this.setState({ status: "" });
                this.props.history.push("/posts");
            }
        }).catch((err) => { // eslint-disable-line no-unused-vars
            console.log(err);
            this.setState({ status: "password_err" });
        });
    };

    render () {
        const { status } = this.state;

        
        return (
            <div className="page__row">
                <h2>Admin Login</h2>
                <Input placeholder="Username" type="text" onChange={e => this.setState({ username: e.target.value })} className="input-user" name="username" autocomplete="name" />
                <Input placeholder="Password" type="password" onChange={e => this.setState({ password: e.target.value })} className="input-user" name="password" autocomplete="name" />
                <Input placeholder="Password again" type="password" onChange={e => this.setState({ passwordRepeat: e.target.value })} className="input-user" name="passwordRepeat" autocomplete="name" />
                <button
                    className="input-btn"
                    onClick={() => this.post()}
                >Send</button>
                {status === "password_err" ? StatusMessage("password_err") : <noscript />}
            </div>
        );
    }
}

export default withRouter(Admin);
