import React from "react";

const Searchbar = ({ onKeyDown }) => {

    return (
        <div id="search">
            <input
                onKeyDown={(e) => onKeyDown(e)}
                placeholder="Leitaðu..."
                type="text"
                name="query"
                id="search-input"
                maxLength="80" 
            />
        </div> 
    );
};

export default Searchbar;

