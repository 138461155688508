import React from "react";


const ListArticle = ({ article, history }) => {
    return (
        <ul key={article.id}>
            <li onClick={(e) => history.push(`/articles/${article.id}`)} className="articleitem">
                <h2>{article.title}</h2>
                <p>{article.author}</p>
            </li>
        </ul>
    );
}

export default ListArticle;

