import React from "react";
import { withRouter } from "react-router-dom";

import { compareDate, getByCriteria } from "../services/common";
import Footer from "../component/Footer/Footer";
import ListArticle from "../component/ListArticle/ListArticle";

class AllArticles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [], 
            nrarticles: 5
        }
    }
    
    componentDidMount() {
        const date = new Date(Date.now()).toISOString();
        const articles = getByCriteria((x => (x.publishdate < date) && x.publish), "articles");
        
        this.setState({ articles: articles });      
    }

    render () {
        const { nrarticles, articles } = this.state;

        return (
            <React.Fragment>
                <div className="page__row">
                    {articles.sort((a, b) => compareDate(a, b)).slice(0, nrarticles).map((ed) => <ListArticle key={ed.id} article={ed} history={this.props.history} />)}
                    {/*eslint-disable-next-line*/}
                    <a
                        href=""
                        style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                        onClick={(e) => {
                            this.setState({ nrarticles: (nrarticles < 500) ? nrarticles + 5 : nrarticles });
                            e.preventDefault();
                        }}
                    >Meira...</a>
                </div>
                <Footer />
            </React.Fragment>

        );
    }
}

export default withRouter(AllArticles);


